<template>
    <div class='news'>
        <h1>充值消费送好礼</h1>
        <ul>
            <li v-for="(text, index) in infos" :key="index">
                <p>{{text}}</p>
            </li>
        </ul>
    </div>
</template>
<script lang='ts'>
import { reactive, toRefs } from 'vue'
export default {
    name: '',
    setup() {
         const state= reactive({
             infos: [
                '日期：2021-07-09',
                '活动时间：2021年7月12日00:00起至2021年9月30日24:00止。',
                '活动彩种：双色球、3D、七乐彩、15选5、天天彩选4、东方6+1。',
                '活动对象：上海福彩电话投注962333注册用户（银行“双色球” 多期投注套餐用户除外）。',
                '参与方式及流程：本次营销活动期间，凡当日投注成功且累计投注金额≥20元，即可获赠机选单式“双色球”彩票2注（价值4元），于次日一次性发送至该用户的电话投注账户。',
                '参与规则：',
                '1、必须是上海福彩电话投注962333注册用户。',
                '2、每个账户每天仅限获赠一次,先到先得，送完即止。',
             ]
        })
        return {
            ...toRefs(state)
        }
    },
}
</script>
<style lang='scss' scoped>
.news {
    h1 {
        font-size: 25px;
        font-weight: 400;
        text-align: center;
        padding-top: 20px;
    }
    ul {
        padding: 20px;
        li {
            padding-bottom: 0.5em;
            p {
                line-height: 1.5em;
            }
        }
    }
}
</style>